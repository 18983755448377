<template>
  <div id="figureEditorToolbar" class="menubar">
    <div class="seperator">
      <div class="breaking-items">
        <div class="zoom-menubar">
          <b-button type="is-info"
                    v-on:click="onZoomSelection"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t(`menubar.${zoomSelectionActive ? 'deactivateZoomSelection' : 'activateZoomSelection'}`)"
          >
            <i class="exi-md exi-zoom-area-in" :class="{'is-active': zoomSelectionActive}"/>
          </b-button>

          <b-button type="is-info"
                    v-on:click="onZoomIn"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t('menubar.zoomIn')"
          >
            <i class="exi-md exi-zoom-in"/>
          </b-button>

          <b-button type="is-info"
                    v-on:click="onZoomOut"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t('menubar.zoomOut')"
          >
            <i class="exi-md exi-zoom-out"/>
          </b-button>

          <b-button type="is-info"
                    v-on:click="onFullZoomOut"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t('menubar.fullZoomOut')"
          >
            <i class="exi-md exi-zoom-100"/>
          </b-button>
          <span class="devider"/>
        </div>


        <div class="metadata-menu">
          <div class="scaling-menu">
            <div>
                <span class="toolbar-text"
                      :class="isDisabled ? 'disabled' : ''">
                  {{ $t('menubar.scaleImage') }}
                </span>
            </div>
            <input :title="$t('menubar.scalingTooltip')"
                   id="scale"
                   type="number"
                   v-model.number="scaling"
                   :min="1"
                   :max="100"
                   @beforeinput="isNumber($event)"
                   :disabled="isDisabled"
                   class="scaling-input"/>
            <div>
              <span class="toolbar-text"> % </span>
            </div>
          </div>

          <b-button type="is-info"
                    class="icon-button"
                    v-on:click="onFullScreenScaling"
                    :disabled="isDisabled"
                    :title="$t('menubar.scaleFullscreen')"
          >
            <i class="exi-md exi-scaleFull"/>
          </b-button>

          <b-button type="is-info"
                    class="icon-button"
                    v-on:click="onContentAreaScaling"
                    :disabled="isDisabled"
                    :title="$t('menubar.scaleContentArea')"
          >
            <i class="exi-md exi-scaleContentArea"/>
          </b-button>

          <span class="devider"/>
        </div>

        <div class="fig-number-div">
          <b-button ref="figNumberButton"
                    type="is-info"
                    v-on:click="onFigureNumberClick"
                    class="icon-button toggle"
                    :class="{'selected': showFigureNumberToggled}"
                    :disabled="isDisabled"
                    :title="$t('menubar.toggleFigureNumber')"
          >
            Fig. #
          </b-button>
        </div>

        <div>
          <b-button type="is-info"
                    class="icon-button"
                    v-on:click="onToggleHelpLines"
                    :disabled="isDisabled"
                    :title="$t(`menubar.${showHelpLines ? 'deactivateHelpLines' : 'activateHelpLines'}`)"
          >
            <i class="exi-md exi-toggleHelpLines" :class="{'is-active': showHelpLines}"/>
          </b-button>
        </div>

        <div class="main-menubar">
          <b-button ref="solidCurveButton"
                    type="is-info"
                    v-on:click="onCreateSolidCurve"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t('menubar.createCurve')"
          >
            <i class="exs exs-draw-curve" :class="{'is-active': solidCreateCurveActive}"/>
          </b-button>

          <b-button ref="dashedCurveButton"
                    type="is-info"
                    v-on:click="onCreateDashedCurve"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t('menubar.createDashedCurve')"
          >
            <i class="exs exs-draw-dashed-curve" :class="{'is-active': dashedCreateCurveActive}"/>
          </b-button>

          <b-button v-if="selectedSymbolsWithUnderlineSupport.length > 0"
                    type="is-info"
                    v-on:click="onUnderlineButtonClick"
                    class="icon-button"
                    :disabled="isDisabled"
                    :title="$t('menububble.underline')"
          >
            <span class="text-icon" style="text-decoration: underline;">{{ $t('text-icon.underline') }}</span>
          </b-button>

          <b-button v-if="deletableSymbols.length > 0"
                    type="is-info delete-button"
                    v-on:click="onDeleteButtonClick"
                    class="delete-button contains-text-icon"
                    :disabled="isDisabled"
                    :title="$t('menububble.delete')">
            <i class="exi exi-delete"/>
            {{ $t('menububble.delete') }}
          </b-button>
        </div>
      </div>

      <div class="stepper">
        <b-button class="icon-button stepper-button" type="is-info"
                  :disabled="disabled"
                  @click="changeFigure(false)">
          <i class="exi exi-triangle-left"/>
        </b-button>

        <input :title="$t('menubar.figureNumberInputTooltip')"
               :disabled="figureNumber === null"
               type="number"
               @beforeinput="isNumber($event)"
               @input="onFigureNumberInputChange($event)"
               :value="figureNumber"
               class="figureStepper"/>

        <b-button class="icon-button stepper-button" type="is-info"
                  :disabled="disabled"
                  @click="changeFigure(true)">
          <i class="exi exi-tree-node-collapsed"/>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import FigureModule from '@/store/modules/FigureModule';
import {FigureEditorMode} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {isTypeWithUnderlineSupport} from '../../../../shared/drawingbasemodule/src/utils/drawingBaseModule.utils';
import {ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';

export type ToolbarClickHandler = () => void;
export type ToolbarScalingChangedHandler = (newScaling: number, withContentArea: boolean) => void;
export type ToolbarShowFigureNumberHandler = (showFigureNumber: boolean) => void;
export type ToolbarFigureNumberChangedHandler = (newFigureNumber: number) => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyClickHandler: ToolbarClickHandler = () => {
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyScalingChangedHandler: ToolbarScalingChangedHandler = (newScaling: number | null, withContentArea: boolean) => {
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyShowFigureNumberChangeHandler: ToolbarShowFigureNumberHandler = (showFigureNumber: boolean) => {
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFigureNumberChangedHandler: ToolbarFigureNumberChangedHandler = (newScaling: number) => {
};

@Component(
  {
    name: 'figureEditorToolbar'
  })
class Toolbar extends Vue {

  @Prop({required: true}) figureList!: ApplicationFigureThumbnail[];
  @Ref('solidCurveButton') private solidCurveButton!: { $el: HTMLButtonElement };
  @Ref('dashedCurveButton') private dashedCurveButton!: { $el: HTMLButtonElement };

  @Prop({required: true, default: false})
  private disabled!: boolean;

  @Prop({required: true, default: () => emptyClickHandler})
  private zoomInClicked!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyClickHandler})
  private zoomOutClicked!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyClickHandler})
  private fullZoomOutClicked!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyScalingChangedHandler})
  private scalingChanged!: ToolbarScalingChangedHandler;

  @Prop({required: true, default: () => emptyShowFigureNumberChangeHandler})
  private figureNumberClicked!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyClickHandler()})
  private toggleHelpLines!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyClickHandler})
  private underlineButtonClicked!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyClickHandler})
  private deleteButtonClicked!: ToolbarClickHandler;

  @Prop({required: true, default: () => emptyFigureNumberChangedHandler})
  private figureNumberChanged!: ToolbarFigureNumberChangedHandler;

  @Prop({required: true, default: false})
  private figureNumberShowing!: boolean;

  @Prop({required: true, default: 1})
  private figureNumberModel!: number | null;

  private scalingValue: number | null = null;

  private get isDisabled(): boolean {
    return this.disabled;
  }

  get selectedSymbols() {
    return FigureModule.selectedSymbols
  }

  get deletableSymbols() {
    return FigureModule.selectedSymbols
      .filter(item => !(item.data.isStartpointSet && !item.data.isEndpointSet));
  }

  get selectedSymbolsWithUnderlineSupport() {
    return this.selectedSymbols
      .filter((symbol) => isTypeWithUnderlineSupport(symbol));
  }

  get figureEditorMode() {
    return FigureModule.figureEditorMode;
  }

  @Watch('figureScaling', {immediate: true})
  private setInputValue() {
    if (FigureModule.applicationFigure?.scaling) {
      this.scalingValue = Math.round(FigureModule.applicationFigure?.scaling * 100);
    }
  }

  get figureScaling() {
    return FigureModule.applicationFigure?.scaling;
  }

  @Watch('figureEditorMode', {immediate: true})
  private figureEditorModeChanged(newFigureEditorMode: FigureEditorMode) {
    // Remove the focus from the buttons
    if (newFigureEditorMode != FigureEditorMode.DASHED_CURVE && newFigureEditorMode != FigureEditorMode.SOLID_CURVE) {
      if (this.solidCurveButton) {
        this.solidCurveButton.$el.blur();
      }
      if (this.dashedCurveButton) {
        this.dashedCurveButton.$el.blur();
      }
    }
  }

  get solidCreateCurveActive() {
    return this.figureEditorMode === FigureEditorMode.SOLID_CURVE;
  }

  get dashedCreateCurveActive() {
    return this.figureEditorMode === FigureEditorMode.DASHED_CURVE;
  }

  get zoomSelectionActive() {
    return this.figureEditorMode === FigureEditorMode.ZOOM_SELECTION;
  }

  get showHelpLines() {
    return FigureModule.showHelpLines;
  }

  get scaling() {
    return this.scalingValue;
  }

  set scaling(value: any) {
    if (value === "" || value === null) {
      this.scalingValue = null;
      return;
    }
    this.scalingValue = value;
    if (this.scalingValue !== null) {
      if (this.scalingValue < 1) {
        this.scalingValue = 1;
      }
      if (this.scalingValue > 100) {
        this.scalingValue = 100;
      }
      this.onScalingChange();
    }
  }

  private onFigureNumberClick(): void {
    this.figureNumberClicked();
  }

  private onToggleHelpLines(): void {
    this.toggleHelpLines();
  }

  private onDeleteButtonClick(): void {
    this.deleteButtonClicked();
  }

  private onUnderlineButtonClick(): void {
    this.underlineButtonClicked();
  }

  private get figureNumber() {
    return this.figureNumberModel;
  }

  private computeNextValidFigureNumberFor(someNumber: number): number {
    if (someNumber >= this.figureListComputed.length + 1) {
      return 1;
    }
    if (someNumber <= 0) {
      return this.figureListComputed.length;
    }
    return someNumber;
  }

  private changeFigure(next: boolean): void {
    if (this.figureNumber === null) {
      return;
    }
    if (next) {
      const nextValidNumber = this.computeNextValidFigureNumberFor(this.figureNumber + 1);
      this.figureNumberChanged(nextValidNumber);
    }
    if (!next) {
      const nextValidNumber = this.computeNextValidFigureNumberFor(this.figureNumber - 1);
      this.figureNumberChanged(nextValidNumber);
    }
  }

  private get figureListComputed(): ApplicationFigureThumbnail[] {
    return this.figureList;
  }

  private get showFigureNumberToggled(): boolean {
    return this.figureNumberShowing && !this.disabled;
  }

  private onCreateSolidCurve(): void {
    if (this.figureEditorMode === FigureEditorMode.SOLID_CURVE) {
      // Deactivate the solid curve mode by setting the default
      FigureModule.activateSelectionMode();
      // Remove the focus from that button
      this.solidCurveButton.$el.blur();
      return;
    }
    FigureModule.activateSolidCurveMode();
  }

  private onCreateDashedCurve(): void {
    if (this.figureEditorMode === FigureEditorMode.DASHED_CURVE) {
      // Deactivate the dashed curve mode by setting the default
      FigureModule.activateSelectionMode();
      // Remove the focus from that button
      this.dashedCurveButton.$el.blur();
      return;
    }
    FigureModule.activateDashedCurveMode();
  }

  private onZoomIn(): void {
    this.zoomInClicked();
  }

  private onZoomOut(): void {
    this.zoomOutClicked()
  }

  private onZoomSelection(): void {
    // toogles the zoom selection
    if (this.zoomSelectionActive) {
      FigureModule.activateSelectionMode();
    } else {
      FigureModule.activateZoomSelectionMode();
    }
  }

  private onFullZoomOut(): void {
    this.fullZoomOutClicked();
  }

  private onScalingChange(): void {
    this.scalingChanged(this.scaling / 100, false);
  }

  private onFullScreenScaling(): void {
    this.scaling = 100;
    this.scalingChanged(this.scaling / 100, false);
  }

  private onContentAreaScaling(): void {
    // this.canvas.setScaling(this.scaling / 100, true);
    // if (FigureModule.applicationFigure?.scaling) {
    //   this.scaling = Math.round(FigureModule.applicationFigure?.scaling * 100);
    // }
    this.scalingChanged(this.scaling / 100, true);
  }

  // utility function to ensure, that only numbers will be entered in the input fields
  private isNumber(evt: InputEvent): void {

    if (isNaN(+evt.data!)) {
      evt.preventDefault();
    }
  }

  private onFigureNumberInputChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (value === '') {
      return;
    }
    const newNumber = this.computeNextValidFigureNumberFor(+value);
    this.figureNumberChanged(newNumber);
  }
}

export default toNative(Toolbar);
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';

#figureEditorToolbar {
  display: flex;
  justify-content: start;
  // min-width: 700px;
  padding: 8px 0px 8px 8px;

  i {
    margin-right: 0;
  }

  button {
    padding: 6px 6px;
  }

  /* toolbar containing zoom controls pushed to the left. */
  .zoom-menubar {
    top: 8px;
    left: 8px;
    display: flex;
  }

  .fig-number-div {
    //flex-grow: 1;
    max-width: 30em;
    text-align: left;
  }

  .metadata-menu {
    display: flex;

    .scaling-menu {

      .toolbar-text {
        white-space: nowrap;
      }
    }
  }

  .breaking-items {
    display: flex;
    width: 100%;
    flex-flow: row wrap;

    .main-menubar {
      display: flex;
      flex-grow: 1;
      justify-content: center;

      .delete-button {

        .exi-delete {
          margin-right: 8px;
        }
      }
    }
  }

  .seperator {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
  }

  .scaling-menu {
    padding-left: 8px;
    padding-top: 4px;
    display: flex;

    .toolbar-text {
      margin-right: 4px;
    }

    .toolbar-text.disabled {
      color: $pengine-grey;
    }
  }

  .scaling-input {
    text-align: right;
    margin-top: 5px;
    margin-left: 2px;
    margin-right: 2px;
    width: 50px;
    padding: 0;
  }

  .devider {
    background-color: $pengine-grey;
    width: 1px;
  }

  .stepper {
    min-width: 90px;
    margin-top: 2px;

    i {
      margin: 0;
    }

    .figureStepper {
      text-align: center;
      -moz-appearance: textfield;
      width: 25px;
    }

    .figureStepper::-webkit-outer-spin-button,
    .figureStepper::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button, button:focus, button:hover {
    background-color: transparent !important;
    color: $pengine-grey-dark-dark !important;
  }

  button.delete-button:hover {
    color: $pengine-delete-red !important;
  }

  button:not(:disabled).icon-button:hover {
    color: $pengine-blue-dark !important;
  }

  button:not(:disabled) {
    i {
      &.is-active {
        background-color: $pengine-blue-dark;
      }

      &:hover {
        background-color: $pengine-blue-dark;
      }
    }

    span {
      &.is-active {
        color: $pengine-blue-dark;
      }

      &:hover {
        color: $pengine-blue-dark;
      }
    }
  }

  .button:disabled {
    &.is-info {
      background-color: #3e8ed0;
      border-color: transparent;
      box-shadow: none;
    }

    &.icon-button {
      i {
        background-color: $pengine-grey-dark-dark;
      }
    }
  }

  .button.is-info[disabled] {
    background-color: #3e8ed0;
    border-color: transparent;
    box-shadow: none;
  }

  .stepper-button {
    padding-top: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .seperator {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .toolbar-text {
    font-weight: normal;
    font-size: 14px;
    color: $pengine-grey-dark-dark;
    height: 24px;
  }

  .toggle {
    font-size: 14px;
    padding-top: 16px;
    height: 24px;
  }

  .selected {
    color: $pengine-blue-dark !important;

    &:hover, &:focus {
      color: $pengine-blue-dark !important;
    }
  }
}
</style>