import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {ReferenceSignListEntry, ReferenceSignState} from '@/store/models/referenceSign.model';
import {
  CreateReferenceSignEvent,
  DeleteReferenceSignEvent,
  ReferenceSign,
  ReferenceSignUpdatedVmUpdate,
  UpdateReferenceSignEvent
} from '@/api/models/referenceSign.model';
import {
  CreateReferenceSign,
  DeleteReferenceSign,
  GenerateStemForms,
  GetReferenceSign,
  UpdateReferenceSign
} from '@/api/services/referenceSign.api';
import {ApplyReferenceSignsForApplicationDocument, GetReferenceSignsForApplicationDocument} from '@/api/services/application.api';
import {ApplyReferenceSignEvent, ReferenceSignsAppliedVmUpdate} from '@/api/models/applyReferenceSigns.model';
import EditorModule from '@/store/modules/EditorModule';
import {FigureSymbolType} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {buildMap} from '@/util/array.util';
import FigureModule from '@/store/modules/FigureModule';

@Module({dynamic: true, namespaced: true, store, name: 'referenceSign'})
class ReferenceSignModule extends VuexModule implements ReferenceSignState {

  // initial state
  private _isLoading = false;
  private _pendingRefernceSigns = new Set<string>();
  private _referenceSigns: ReferenceSign[] = [];
  private _referenceSignDummy = null as ReferenceSign | null;

  private static REFERENCE_SIGN_DUMMY_GUID = "dummy-id";

  get isLoading(): boolean {
    return this._isLoading;
  }

  get referenceSigns(): ReferenceSign[] {
    if (this._referenceSignDummy) {
      return this._referenceSigns.concat([this._referenceSignDummy]);
    }
    return this._referenceSigns;
  }

  get referenceSignDummy(): ReferenceSign | null {
    return this._referenceSignDummy;
  }

  get referenceSignDummyGuid(): string {
    return ReferenceSignModule.REFERENCE_SIGN_DUMMY_GUID;
  }

  get referenceSignListEntries(): ReferenceSignListEntry[] {
    if (!this.referenceSigns) {
      return [];
    }
    let index = 0;
    return this.referenceSigns.map((referenceSign: ReferenceSign): ReferenceSignListEntry => {
      // only use if it's not falsy (null, undefined, '')
      const labelExplizit = referenceSign.label ? referenceSign.label : undefined;
      const labelResulting = (() => {
        if (labelExplizit) {
          return labelExplizit;
        } else {
          do {
            index++
          }
          while (this.referenceSigns.some(it => it.label === index.toString()));
          return index.toString();
        }
      })();

      return {
        guid: referenceSign.guid as string,
        edit: referenceSign.guid === this.referenceSignDummyGuid,
        pending: this._pendingRefernceSigns.has(referenceSign.guid!),
        referenceSign: referenceSign,
        labelExplizit: labelExplizit,
        labelResulting: labelResulting,
        name: referenceSign.name,
        type: FigureSymbolType.REFERENCE_SIGN_MARKER,
        numberMatchesOnReferenceSign: referenceSign.numberMatchesOnReferenceSign
      };
    });
  }

  get referenceSignListEntryMap(): Map<string, ReferenceSignListEntry> {
    return buildMap(this.referenceSignListEntries, (obj) => obj.guid)
  }

  // Called after loading or updating a reference sign to replace the old one in the list or add it if it didn't exist yet
  static replaceOrPushReferenceSign(referenceSigns: ReferenceSign[], referenceSign: ReferenceSign | null) {
    if (referenceSign) {
      const index = referenceSigns.findIndex((oldReferenceSign) => oldReferenceSign.guid === referenceSign.guid);
      if (index === -1) {
        referenceSigns.push(referenceSign);
      } else {
        // Dont use 'referenceSigns[index] = referenceSign' as vue doesn't register the update
        referenceSigns.splice(index, 1, referenceSign);
      }
    }
  }

  @Mutation
  public setReferenceSignLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  public setReferenceSignNotLoading(): void {
    this._isLoading = false;
  }

  @Mutation
  public setReferenceSigns(referenceSigns: ReferenceSign[]): void {
    this._referenceSigns = referenceSigns;
  }

  @Mutation
  private fetchReferenceSignEnd(referenceSign: ReferenceSign | null) {
    ReferenceSignModule.replaceOrPushReferenceSign(this._referenceSigns, referenceSign);
    this._isLoading = false;
  }

  @Mutation
  private createReferenceSignEnd(createdReferenceSign: ReferenceSign | null) {
    ReferenceSignModule.replaceOrPushReferenceSign(this._referenceSigns, createdReferenceSign);
    this._isLoading = false;
  }

  @Mutation
  public createReferenceSignDummy(applicationDocument: string) {
    this._referenceSignDummy = {
      guid: ReferenceSignModule.REFERENCE_SIGN_DUMMY_GUID,
      applicationDocument: applicationDocument,
      label: "",
      name: "",
      stemForms: [],
      excludeStemFormMatches: [],
      stemFormMatches: [],
      numberMatchesOnReferenceSign: 0
    };
  }

  @Mutation
  public resetReferenceSignDummy() {
    this._referenceSignDummy = null;
  }

  @Mutation
  private setReferenceSignPending(guid: string) {
    this._pendingRefernceSigns.add(guid);
  }

  @Mutation
  private updateReferenceSignEnd(payload: { updatedReferenceSign: ReferenceSign | null; originalGuid: string }) {
    ReferenceSignModule.replaceOrPushReferenceSign(this._referenceSigns, payload.updatedReferenceSign);
    this._isLoading = false;
    if (payload.updatedReferenceSign) {
      this._pendingRefernceSigns.delete(payload.originalGuid);
    }
  }

  @Mutation
  private deleteReferenceSignEnd(deletedReferenceSign: ReferenceSign | null) {
    if (deletedReferenceSign) {
      const index = this._referenceSigns.findIndex((oldReferenceSign) => oldReferenceSign.guid === deletedReferenceSign.guid);
      if (index > -1) {
        this._referenceSigns.splice(index, 1);
      }
    }
    this._isLoading = false;
  }

  @Mutation
  public fetchReferenceSignsForApplicationDocumentEnd(referenceSigns: ReferenceSign[] | null) {
    if (referenceSigns) {
      this._referenceSigns = referenceSigns;
    }
    this._isLoading = false;
  }

  @Action
  async fetchReferenceSignsForApplicationDocument(guid: string): Promise<void> {
    this.setReferenceSigns([]);
    this.setReferenceSignLoading();
    return GetReferenceSignsForApplicationDocument(guid).then((referenceSigns) => {
      this.fetchReferenceSignsForApplicationDocumentEnd(referenceSigns);
    }).catch((error) => {
      this.fetchReferenceSignsForApplicationDocumentEnd(null);
      throw error;
    });
  }

  @Action
  async fetchReferenceSign(guid: string): Promise<void> {
    return GetReferenceSign(guid).then((referenceSign) => {
      this.fetchReferenceSignEnd(referenceSign)
    }).catch((error) => {
      this.fetchReferenceSignEnd(null)
      throw error;
    });
  }

  @Action
  async createReferenceSignFromDummy(event: CreateReferenceSignEvent): Promise<string> {
    return this.createReferenceSign(event)
      .then(newGuid => {
        this.resetReferenceSignDummy();
        return newGuid;
      });
  }

  @Action
  async createReferenceSign(event: CreateReferenceSignEvent): Promise<string> {
    this.setReferenceSignLoading();
    return CreateReferenceSign(event)
      .then((createdReferenceSign: ReferenceSign) => {
        this.createReferenceSignEnd(createdReferenceSign);
        return createdReferenceSign.guid as string;
      }).catch((error) => {
        this.createReferenceSignEnd(null);
        throw error;
      });
  }

  @Action({rawError: true})
  async updateReferenceSign(event: UpdateReferenceSignEvent): Promise<ReferenceSign> {
    this.setReferenceSignLoading();
    this.setReferenceSignPending(event.guid);
    return UpdateReferenceSign(event)
      .then((vmUpdate: ReferenceSignUpdatedVmUpdate) => {
        const {referenceSign: updatedReferenceSign, affectedSymbols} = vmUpdate;
        this.updateReferenceSignEnd({updatedReferenceSign: updatedReferenceSign, originalGuid: event.guid});

        if (affectedSymbols.length > 0) {
          FigureModule.updateSymbolsInStore(affectedSymbols);
          affectedSymbols.forEach((symbol) => {
            FigureModule.symbolTracker.addEntryChangedDate(symbol.guid);
          });
          FigureModule.setSymbolsFromBackend(true);
        }
        return updatedReferenceSign;
      }).catch((error) => {
        this.updateReferenceSignEnd({updatedReferenceSign: null, originalGuid: event.guid});
        throw error;
      })
  }

  @Action
  async refreshStemForms(referenceSignGuid: string): Promise<ReferenceSign> {
    this.setReferenceSignLoading();
    this.setReferenceSignPending(referenceSignGuid);
    return GenerateStemForms(referenceSignGuid).then((updatedReferenceSign: ReferenceSign) => {
      this.updateReferenceSignEnd({updatedReferenceSign: updatedReferenceSign, originalGuid: referenceSignGuid});
      return updatedReferenceSign;
    }).catch((error) => {
      this.updateReferenceSignEnd({updatedReferenceSign: null, originalGuid: referenceSignGuid});
      throw error;
    })
  }

  @Action
  async deleteReferenceSign(event: DeleteReferenceSignEvent): Promise<void> {
    this.setReferenceSignLoading();
    return DeleteReferenceSign(event.referenceSign.guid as string)
      .then(() => {
        this.deleteReferenceSignEnd(event.referenceSign);
      })
      .catch((error) => {
        this.deleteReferenceSignEnd(null);
        throw error;
      });
  }

  @Action({rawError: true})
  async applyReferenceSignsForApplicationDocument(event: ApplyReferenceSignEvent): Promise<void> {
    this.setReferenceSignLoading();
    EditorModule.setApplyReferenceSignForApplicationDocumentLoading(true);
    return ApplyReferenceSignsForApplicationDocument(event.applicationDocumentGuid)
      .then((processedVmUpdate: ReferenceSignsAppliedVmUpdate) => {
        this.fetchReferenceSignsForApplicationDocumentEnd(processedVmUpdate.referenceSigns);
        EditorModule.pushDocumentUpdate(processedVmUpdate.name);
        EditorModule.updateBlocks(processedVmUpdate.changedBlocks);
        EditorModule.updateUndoRedoStack(processedVmUpdate.commandStack);
        EditorModule.setApplyReferenceSignForApplicationDocumentLoading(false);
      })
      .catch((error) => {
        this.fetchReferenceSignsForApplicationDocumentEnd(null);
        EditorModule.setApplyReferenceSignForApplicationDocumentLoading(false);
        throw error;
      });
  }
}

export default getModule(ReferenceSignModule);
