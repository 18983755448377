import {
  Brace,
  Curve,
  FigureSymbol,
  FigureSymbolType,
  HelpLine,
  Line,
  ReferenceSignMarker,
  ReferredReferenceSign
} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {CreateSymbolEvent} from '@/api/models/figureSymbol.model';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import {required} from '../../../../shared/drawingbasemodule/src/utils/drawingBaseModule.utils';
import {CreateHelpLineEvent} from '@/api/models/helpLine.model';
import {CreateReferenceSignMarkerEvent} from '@/api/models/referenceSignMarker.model';

/**
 * Map list with reference sign guids to ReferredReferenceSign DTOs that also contain name and label.
 */
export function buildReferredReferenceSignList(guids: string[]): ReferredReferenceSign[] {
  return [...new Set(guids)]
    .map(guid => {
      const entry = ReferenceSignModule.referenceSignListEntryMap.get(guid);
      return {
        guid: guid,
        name: entry?.name ?? "???",
        assignedLabel: entry?.labelResulting ?? "???"
      };
    });
}

function createFigureSymbolLine(event: CreateSymbolEvent): Line {
  return {
    guid: event.guid,
    figureGuid: event.figureGuid,
    symbolType: event.symbolType,
    x1: event.x1,
    y1: event.y1,
    x2: required(event.x2),
    y2: required(event.y2),
    dashed: required(event.dashed),
    snapPoints: []
  };
}

function createFigureSymbolCurve(event: CreateSymbolEvent): Curve {
  return {
    guid: event.guid,
    figureGuid: event.figureGuid,
    symbolType: event.symbolType,
    x1: event.x1,
    y1: event.y1,
    x2: required(event.x2),
    y2: required(event.y2),
    dashed: required(event.dashed),
    handle1x: event.handle1x,
    handle1y: event.handle1y,
    handle2x: event.handle2x,
    handle2y: event.handle2y,
    snapPoints: []
  };
}

function createFigureSymbolBrace(event: CreateSymbolEvent): Brace {
  return {
    guid: event.guid,
    figureGuid: event.figureGuid,
    symbolType: event.symbolType,
    x1: event.x1,
    y1: event.y1,
    x2: required(event.x2),
    y2: required(event.y2),
    braceType: required(event.braceType),
    snapPoints: []
  };
}

export function createFigureSymbolReferenceSignMarker(event: CreateReferenceSignMarkerEvent): ReferenceSignMarker {
  // We need to refer to the existing reference signs for label and name
  const referenceSigns = buildReferredReferenceSignList(required(event.referenceSigns));
  return {
    guid: event.guid,
    figureGuid: event.figureGuid,
    symbolType: event.symbolType,
    topLeftX: event.topLeftX,
    topLeftY: event.topLeftY,
    bottomRightX: event.bottomRightX,
    bottomRightY: event.bottomRightY,
    referenceSigns: referenceSigns,
    underlined: required(event.underlined),
    horizontal: null,
    vertical: null,
    snapPoints: []
  };
}

export function createHelpLine(event: CreateHelpLineEvent): HelpLine {
  return {
    guid: event.guid,
    figureGuid: event.figureGuid,
    symbolType: event.symbolType,
    orientation: event.orientation,
    coordinate: event.coordinate,
    horizontal: null,
    vertical: null
  };
}

export type CreateEvent = CreateSymbolEvent | CreateHelpLineEvent | CreateReferenceSignMarkerEvent;

/**
 * Create a FigureSymbol from a CreateSymbolEvent, bypassing the backend to get an immediate GUI update.
 *
 * @param event CreateSymbolEvent
 * @return FigureSymbol
 */
export function createFigureSymbol(event: CreateEvent): FigureSymbol {
  switch (event.symbolType) {
    case FigureSymbolType.LINE: {
      return createFigureSymbolLine(event as CreateSymbolEvent)
    }
    case FigureSymbolType.ARROW: {
      return createFigureSymbolLine(event as CreateSymbolEvent)
    }
    case FigureSymbolType.CURVE: {
      return createFigureSymbolCurve(event as CreateSymbolEvent)
    }
    case FigureSymbolType.BRACE: {
      return createFigureSymbolBrace(event as CreateSymbolEvent);
    }
    case FigureSymbolType.REFERENCE_SIGN_MARKER: {
      return createFigureSymbolReferenceSignMarker(event as CreateReferenceSignMarkerEvent);
    }
    case FigureSymbolType.HELP_LINE: {
      return createHelpLine(event as CreateHelpLineEvent);
    }
    default: {
      throw new Error(`Given symbol type ${event.symbolType} is not supported.`);
    }
  }
}
