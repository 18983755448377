import {
  FigureOrientation,
  FigureSymbol,
  HelpLine,
  ImageType,
  ReferenceSignMarker
} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {CreateSymbolEvent, UpdateSymbolEvent} from '@/api/models/figureSymbol.model';
import {CreateHelpLineEvent, UpdateHelpLineEvent} from '@/api/models/helpLine.model';
import {CreateReferenceSignMarkerEvent, UpdateReferenceSignMarkerEvent} from '@/api/models/referenceSignMarker.model';
import {ReferenceSign, ReferenceSignMarkerPrototype} from '@/api/models/referenceSign.model';

export interface ApplicationFigureThumbnail {
  guid: string;
  position: number;
  isMainFigure: boolean;
  description?: string;
  // eslint-disable-next-line
  thumbnail: string; // byte[]
}

export interface CreateApplicationFigureEvent {
  // For sending to the server
  applicationDocument: string; // Using guid
  orientation: FigureOrientation;
}

export interface UpdateApplicationFigureEvent {
  guid: string;
  isMainFigure: boolean;
  position: number;
  description: string;
}

export interface ApplicationFigureCreatedVmUpdate {
  applicationFigure: ApplicationFigureThumbnail;
}

export interface ApplicationFigureUpdatedVmUpdate {
  applicationFigure: ApplicationFigureThumbnail;
  guid: string;
  isMainFigure: boolean;
  description: string;
}

export interface ApplicationFigureDeletedVmUpdate {
  guid: string;
}

export interface ApplicationFigurePreview {
  guid?: string;
  position: number;
  // eslint-disable-next-line
  fullResolution: any; // byte[]
  orientation: FigureOrientation;
  upToDate: boolean;
  error: boolean;
}

export interface ApplicationFigureEdit {
  guid?: string;
  position: number;
  totalFiguresInDocument: number;
  orientation: FigureOrientation;
  scaling: number;
  showFigureNumber: boolean;
  // eslint-disable-next-line
  image: any; // byte[]
  imageType: ImageType;
  description?: string;
  figureSymbols: FigureSymbol[];
}

export interface ApplicationFigureScalingUpdatedVmUpdate {
  scaling: number;
}

export interface ApplicationFigureShowFigNumberUpdatedVmUpdate {
  showFigureNumber: boolean;
}

export interface CreateApplicationFigureSymbolsEvent {
  helpLines: CreateHelpLineEvent[];
  referenceSignMarkers: CreateReferenceSignMarkerEvent[];
  symbols: CreateSymbolEvent[];
}

export interface ApplicationFigureSymbolsCreatedVmUpdate {
  helpLines: HelpLine[];
  referenceSignMarkers: ReferenceSignMarker[];
  symbols: FigureSymbol[];
}

export interface UpdateApplicationFigureSymbolsEvent {
  helpLines: UpdateHelpLineEvent[];
  referenceSignMarkers: UpdateReferenceSignMarkerEvent[];
  symbols: UpdateSymbolEvent[];
}

export interface ApplicationFigureSymbolsUpdatedVmUpdate {
  helpLines: HelpLine[];
  referenceSignMarkers: ReferenceSignMarker[];
  symbols: FigureSymbol[];
}

export interface DeleteApplicationFigureSymbolsEvent {
  helpLineGuids: string[];
  referenceSignMarkerGuids: string[];
  symbolGuids: string[];
}

export interface ApplicationFigureSymbolsDeletedVmUpdate {
  helpLineGuids: string[];
  referenceSignMarkerGuids: string[];
  symbolGuids: string[];
}

export interface InsertElementsEvent {
  figureGuid: string;
  userConsent: UserConsent;
  offset: number;
  referenceSignMarkers: ReferenceSignMarkerPrototype[];
  helpLines: CreateHelpLineEvent[];
  symbols: CreateSymbolEvent[];
}

export enum UserConsent {
  NOT_ASKED = "NOT_ASKED",
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED"
}

export interface InsertFigureElementVmUpdate {
  askForConsent: boolean;
  updatedReferenceSignList: ReferenceSign[];
  referenceSignMarkers: ReferenceSignMarker[];
  helpLines: HelpLine[];
  symbols: FigureSymbol[];
}